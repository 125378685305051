import {motion} from 'framer-motion'
import { useEffect } from 'react'

const PageTransitions = ({routeName}) => {

  useEffect(() => {
    console.log(routeName.typeOff)
  }, []);

  return (
    <>
          <motion.div
          className='transition black transition-none' 
          initial={{translateX:'100%'}}
          animate={{translateX: '100%'}}
          exit={{translateX: 0}}
          transition={{duration: .46, delay: .15 ,ease: [.77,.35,.38,.79]}}/>
          <motion.div
          className='transition green transition-none' 
          initial={{translateX:'100%'}}
          animate={{translateX: '100%'}}
          exit={{translateX: 0}}
          transition={{duration: .46, delay: .1, ease: [.77,.35,.38,.79]}}/>
          <motion.div
          className='transition violet transition-none' 
          initial={{translateX:'100%'}}
          animate={{translateX: '100%'}}
          exit={{translateX: 0}}
          transition={{duration: .46, ease: [.77,.35,.38,.79]}}/>

          <motion.div
          className='transition transition-none' 
          initial={{translateY:'0'}}
          animate={{translateY: '100%'}}
          transition={{duration: .46, delay: 1.1, ease: [.77,.35,.38,.79]}}>
            <div className='overflow-box relative'>
              <motion.span
              className='block transition-heading-gradient'
              initial={{translateY:'100%'}}
              animate={{translateY: '0'}}
              transition={{duration: .5, ease: [.77,.35,.38,.79]}}
              >{routeName}</motion.span>
            </div>
          </motion.div>

          <motion.div
          className='transition green home transition-none' 
          initial={{translateY:'0'}}
          animate={{translateY: '100%'}}
          transition={{duration: .46, delay: 1.2, ease: [.77,.35,.38,.79]}} />
          <motion.div
          className='transition violet home transition-none' 
          initial={{translateY:'0'}}
          animate={{translateY: '100%'}}
          transition={{duration: .46, delay: 1.3, ease: [.77,.35,.38,.79]}} />         
        </>
  )
}

export default PageTransitions