import { useParams } from "react-router-dom"
import {data as projetcs} from '../data/projects.js'
import PageTransitions from "../components/PageTransition.jsx"
import '../pages/SingleProject.css'
import SingleProjectHeader from "../components/singleProject/SingleProjectHeader.jsx"
import { ContactSection } from "../components/ContactSection.jsx"
import { useEffect } from "react"
import HorizontalImages from "../components/singleProject/HorizontalImages.jsx"
import SingleHero from "../components/singleProject/SingleHero.jsx"
import Footer from "../components/Footer.jsx"
import { SingleProjectDetails } from "../components/singleProject/SingleProjectDetails.jsx"

const SingleProject = ({lenis}) => {
    const params = useParams()

    console.log(params.slug)


    let renderedProject = ''
    projetcs.forEach(loopedProject => {
        if(loopedProject.slug === params.slug){
            renderedProject = loopedProject
        }
    })    
    lenis.scrollTo(0,{duration: 0, lerp:20})
    
  return (
    <div>
        <PageTransitions routeName={renderedProject.name} />
        <SingleProjectHeader lenis={lenis} />
        <main id="single-project">
            <SingleHero project={renderedProject}/>
            <HorizontalImages images={renderedProject.detail_images}/>
            <SingleProjectDetails project={renderedProject} />
        </main>
        <div className="sticky-bottom">
            <ContactSection  />
            <Footer/>
        </div>       
    </div>
    
    
  )
}

export default SingleProject