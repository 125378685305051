// DATA
import { useRef, useEffect } from 'react';
import {data as projetcs} from '../data/projects.js'
import SplitType from "split-type"
import gsap from 'gsap';
import { Link } from 'react-router-dom';
import { useGSAP } from '@gsap/react';
import tabsArrow from '../img/tabs-arrow.svg'
import blackArrow from '../img/black-arrow.svg'
import ScrollTrigger from 'gsap/dist/ScrollTrigger';

export default function ProjectsList() {

  const ulRef = useRef()

  useGSAP(() => {
    

    gsap.registerPlugin(ScrollTrigger)

    const projectLiAll = document.querySelectorAll('#featured-projects li')
    const projectsH2 = document.querySelector('#featured-projects h2')
    const projectsH2Paragraph = document.querySelector('#featured-projects .container .overflow-box > p')

    gsap.fromTo(projectsH2, {y: '115%'},{y:0, duration:.5,  ease:"Expo.Ease",scrollTrigger:{
        trigger: projectsH2
    }})
    gsap.fromTo(projectsH2Paragraph, {y: '115%'},{y:0, duration: .5, delay: .2, ease:"Expo.Ease",scrollTrigger:{
        trigger: projectsH2Paragraph
    }})     

    projectLiAll.forEach(li => {

      const entryTl = gsap.timeline()

      let mainImg = li.querySelector('.img-box > img')
      let arrowBox = li.querySelector('.arrow-box')

      entryTl.fromTo(mainImg,{xPercent: 100, opacity:0},{xPercent:0, opacity:1 ,duration: 1, delay: .3, ease: 'power4'})
      entryTl.fromTo(arrowBox,{scale:0},{scale: 1, ease: 'power4.out', onComplete:() => {
        li.classList.add('animation-complete')
      }},'-=.3')

      ScrollTrigger.create({
        trigger: li,
        start: 'top 60%',
        animation: entryTl
      })


      const bottomContent = li.querySelector('.bottom-content')
      let h3 = li.querySelector('h3')
      let link = li.querySelector('.view-more')
      
      const bottomTl = gsap.timeline({delay: .7})
      const h3Split = new SplitType(h3)

      bottomTl.from(h3Split.chars,{opacity: 0, x: 10, duration: .8, stagger: .025})
      bottomTl.fromTo(link,{opacity:0, y:20},{opacity: 1, y: 0, duration: .4, ease: 'power4.out'}, '-=1.3')

      ScrollTrigger.create({
        trigger: bottomContent,
        animation: bottomTl
      })     
    });
  }, []);

  return (
    <ul ref={ulRef}>
        {
            projetcs.map((project) =>{
                return <li key={project.id}>
                          <Link href={'#'} to={`/${project.slug}`}>
                              <div className='overflow-box img-box'>
                                <img src={project.img} alt="" />
                                <div className="overflow-box arrow-box">
                                  <img src={blackArrow} alt="" />
                                  <img src={tabsArrow} alt="" />
                                </div>
                              </div>                                                    
                              
                              <div className='bottom-content'>
                                <h3>{project.name}</h3>
                                <Link href={'#'} to={`/${project.slug}`} className="body-link view-more">View More</Link>
                              </div>    
                          </Link>                                                    
                        </li>
            })
        }                  
    </ul>
  )
}
