// ASSETS
import "./Home.css"
import {animate, delay, motion} from 'framer-motion'

// UTILS
import ScrollTrigger from "gsap/ScrollTrigger"

// COMPONENTS
import Header from "../components/Header"
import Footer from "../components/Footer"
import ProjectsList from "../components/ProjectsList"
import { ContactSection } from "../components/ContactSection"

import gsap from "gsap"
import { TabsSection } from "../components/TabsSection"
import HomeHero from "../components/HomeHero"


const animationContainerVariants = {
    initial: {},
    animate: {

      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.06,
        delay: .2
        
      }
    }
  }

const animationLetterVariants = {
    initial: { y: "-110%" },
    animate: {
      y: 0,
      transition:{ease: [.88,.02,.36,.98], duration: .8},
    }
  }

function Home({lenis}) {
    lenis.scrollTo(0,{duration: 0, lerp:20})

    gsap.registerPlugin(ScrollTrigger)

   

    return ( 
        <>
            <Header lenis={lenis} gsap={gsap}/>
            <motion.div
          className='transition black transition-none' 
          initial={{translateX:'0%'}}
          animate={{translateX: '-100%'}}
          exit={{translateX: 0}}
          transition={{duration: .46, delay: .15, ease: [.77,.35,.38,.79]}}/>
          <motion.div
          className='transition green transition-none' 
          initial={{translateX:'0%'}}
          animate={{translateX: '-100%'}}
          exit={{translateX: 0}}
          transition={{duration: .46, delay: .1 , ease: [.77,.35,.38,.79]}}/>
          <motion.div
          className='transition violet transition-none' 
          initial={{translateX:'0%'}}
          animate={{translateX: '-100%'}}
          exit={{translateX: 0}}
          transition={{duration: .46, delay: 0, ease: [.77,.35,.38,.79]}}/>


          <motion.div
          className='transition black home transition-none' 
          initial={{translateY:'0'}}
          animate={{translateY: '100%'}}
          transition={{duration: .46, delay: 1.4, ease: [.77,.35,.38,.79]}}>
            <motion.div variants={animationContainerVariants} initial="initial" animate={'animate'} className='overflow-box transition-none'>
              <motion.span variants={animationLetterVariants}
              className='block transition-none transition-heading-gradient'
             
             
              >G</motion.span>
              <motion.span variants={animationLetterVariants} 
              className='block transition-none transition-heading-gradient'
              
              >B</motion.span>
              <motion.span variants={animationLetterVariants}
              className='block transition-none transition-heading-gradient'
              
              
              >O</motion.span>
            </motion.div>
          </motion.div>

          <motion.div
          className='transition green home transition-none' 
          initial={{translateY:'0'}}
          animate={{translateY: '100%'}}
          transition={{duration: .46, delay: 1.5, ease: [.77,.35,.38,.79]}} />
          <motion.div
          className='transition violet home transition-none' 
          initial={{translateY:'0'}}
          animate={{translateY: '100%'}}
          transition={{duration: .46, delay: 1.6, ease: [.77,.35,.38,.79]}} />
           

            <main>
                    <HomeHero lenis={lenis} />
                    <section id="featured-projects">
                        <div className="container">
                            <div className="overflow-box">
                                <h2 className="black-heading-gradient">Selected Projects</h2>
                            </div>
                            <div className="overflow-box">
                                <p className="subtitle">Here are some of my selected works for you to get to know my profile a little better.</p>
                            </div>
                           
                            <ProjectsList/>                        
                            
                            {/* <ul>
                            {
                                projetcs.map((project,i) =>{
                                    return <ProjectItem  dataScrollSpeed={i % 2 !== 0 ? '2' : ''} key={project.name} link={project.link} id={project.id} name={project.name} type={project.type} src={project.img}/>
                                })
                            }
                            
                            </ul> */}
                        </div>
                    </section>
                    <TabsSection lenis={lenis}/>
                </main>
                <div className="sticky-bottom">
                    <ContactSection />            
                    <Footer/>  
                </div>                 
                                 
        </>
     );
}

export default Home;
