import SplitType from "split-type"
import gsap from "gsap"
import { useGSAP } from "@gsap/react"

// Icons
import heroCircle from '../img/hero-circle.svg'
import gitHubIcon from '../img/github-icon.svg'
import psICon from '../img/ps-icon.svg'
import nodeIcon from '../img/node-icon.svg'
import figmaIcon from '../img/figma-icon.svg'
import tailwindIcon from '../img/tailwind-icon.svg'
import vueLogo from '../img/vue-logo.svg'
import firebaseLogo from '../img/firebase-logo.svg'
import framerMotionLogo from '../img/framer-motion-logo.svg'
import viteLogo from '../img/vite-logo.svg'
import reactLogo from '../img/react-logo.svg'

const HomeHero = ({lenis}) => {

    useGSAP(() => {

        // HERO ANIMATIONS

        lenis.on('scroll', ({scroll}) => {
            const scrollIcons = document.querySelector('.locomotive-list')
            if(scrollIcons) scrollIcons.style = `transform: translateX(${scroll}px)`
        })

        const wordsSplitType = new SplitType('.right-content p', {
            type: 'words',
          });

          // Dividir el texto en líneas
          const words = wordsSplitType.words;

          console.log(words)

          var wordss = document.querySelectorAll('.right-content .line');
    
          wordss.forEach(function(line) {
              var lineContainer = document.createElement('div');
              lineContainer.className = 'line-container';
              lineContainer.style.overflow = 'hidden';
      
              // Mover el elemento .line dentro del nuevo contenedor
              line.parentNode.insertBefore(lineContainer, line);
              lineContainer.appendChild(line);
          });
 
        
        const heroTl = gsap.timeline({delay: 1.5})

       
        const paragraph = document.querySelector('#hero .left-content p')
        const link = document.querySelector('#hero .left-content a')
        const rightContentImg = document.querySelector('#hero .right-content img')
        const iconList = document.querySelector('#hero  ul')
        const heading = document.querySelector('#hero h2')

        
        heroTl.from(heading, {opacity:0 ,y: 330 , duration: .85})
        heroTl.fromTo(paragraph, {y: '100%'},{y:0, ease:"Expo.Ease",duration:.5}, '-=.25')
        heroTl.fromTo(link, {y: '100%'},{y:0,ease:"Expo.Ease", duration:.5}, '-=.35')
        // Utiliza el método fromTo para animar los elementos con la clase .line
        heroTl.fromTo(words, {
            x: 10,
            opacity: 0
        }, {
            opacity:1,
            x: 0,
            stagger: 0.015, // Aplica un retraso de 0.1 segundos entre cada elemento
            duration: 2,
            ease: "expo.out", // Puedes ajustar la duración de la animación
        },'-=.7');
        heroTl.fromTo(rightContentImg, {opacity: 0, y: 30},{opacity: 1, y:0 , duration: 1}, '-=2')
        heroTl.fromTo(iconList, {opacity: 0, y: -30},{opacity: 1, y:0 , duration: 1}, '-=1.8')
        
    }, []);

  return (
    <section id="hero">
        <div className="container">
            <div className="left-content">
                <div className="overflow-box">
                    <h2 className="black-heading-gradient">Front End</h2>
                </div>
                <div className="overflow-box">
                    <p>Web Developer.</p>
                </div>
                <div className="overflow-box">
                    <a onClick={() => lenis.scrollTo('#featured-projects',{offset: -30})} href="#featured-projects" className="body-link"> My Projects</a>
                </div>
            </div>
            <div className="right-content">
                <p>Hello, my name is Gonzalo. I am a front-end web development and design enthusiast. I aim to create enjoyable web experiences for users.</p>
                <img data-scroll data-scroll-speed="3" className="hero-circle" src={heroCircle} alt="Imagen vectorial que me representa" />
            </div>                        
        </div>
        <ul className="icon-list">
            <div className="locomotive-list">
            <div>
                    <li>
                        <img src={gitHubIcon} alt="" />
                    </li>
                    <li>
                        <img src={figmaIcon} alt="" />
                    </li>
                    <li>
                        <img src={psICon} alt="" />
                    </li>
                    <li>
                        <img src={tailwindIcon} alt="" />
                    </li>
                    <li>
                        <img src={framerMotionLogo} alt="" />
                    </li>
                    <li>
                        <img src={firebaseLogo} alt="" />
                    </li>
                </div>
                <div>
                    <li>
                        <img src={gitHubIcon} alt="" />
                    </li>
                    <li>
                        <img src={figmaIcon} alt="" />
                    </li>
                    <li>
                        <img src={viteLogo} alt="" />
                    </li>
                    <li>
                        <img src={vueLogo} alt="" />
                    </li>
                    <li>
                        <img src={reactLogo} alt="" />
                    </li>
                    <li>
                        <img src={nodeIcon} alt="" />
                    </li>
                </div>
                <div>
                    <li>
                        <img src={gitHubIcon} alt="" />
                    </li>
                    <li>
                        <img src={figmaIcon} alt="" />
                    </li>
                    <li>
                        <img src={firebaseLogo} alt="" />
                    </li>
                    <li>
                        <img src={tailwindIcon} alt="" />
                    </li>
                    <li>
                        <img src={reactLogo} alt="" />
                    </li>
                    <li>
                        <img src={nodeIcon} alt="" />
                    </li>
                </div>              
            </div>                                 
        </ul>  
    </section>
  )
}

export default HomeHero